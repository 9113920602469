const PAGE_NAMES = {
  LANDING_PAGE: 'landing-page',

  REGISTRATION: {
    STEP_1_VNR_FORM: 'registration:step1:vnr-form',
    STEP_2_USER_FORM: 'registration:step2:user-form',
    REGISTRATION_CONFIRMATION: 'registration:step3:verification-email-sent',
    REGISTRATION_CONFIRMATION_ERROR: 'registration:step3:error',
    EMAIL_VERIFICATION: 'registration:step4:email-verifed',
  },
  PORTAL: {
    USER_SETTINGS: 'user-settings:overview',
    LOGGED_OUT: 'user-settings:logout',
    ACCOUNT_DELETED: 'user-settings:account-deleted',
  },
  PASSWORD_RESET: {
    INIT: 'password-reset:step1:email',
    INIT_CONFIRMATION: 'password-reset:step2:reset-email-sent',
    SET_NEW_PASSWORD_FORM: 'password-reset:step3:enter-new-password',
    NEW_PASSWORD_SET_SUCCESSFULLY: 'password-reset:step4:new-password-set',
    SET_NEW_PASSWORD_TOKEN_EXPIRED: 'password-reset:step4:link-expired',
  },
}

export default PAGE_NAMES
