import axios from 'axios'

export default class PortalService {
  /**
   * @type axios
   */
  httpClient
  // TODO: {4} parametrize window.location.href
  constructor() {
    this.httpClient = axios.create()
    this.httpClient.interceptors.response.use(
      (response) => response,
      (err) => {
        const statusCode = err.response.status

        if (statusCode === 401) {
          this.httpClient
            .get('/api/v1/loginUrl')
            .then((res) => {
              window.location.replace(res.data.url)
            })
            .catch((error) => {
              console.error(error)
              alert('Unexpected error: ' + error.message)
            })
        }

        if (statusCode === 423) {
          const message = 'Ihr Benutzerkonto ist noch nicht aktiviert'
          alert(message)
        }

        if (statusCode >= 500) {
          const message =
            'Es ist ein Kommunikationsfehler aufgetreten (' +
            statusCode +
            ').\nBitte versuchen Sie es später erneut.'
          alert(message)
        }

        return Promise.reject(err.response)
      }
    )
  }

  async getUserInfo() {
    const result = await this.httpClient.get('/api/v1/user')
    return result.data
  }

  async setUserInfo({ gender, title, firstName, lastName }) {
    const result = await this.httpClient.put('/api/v1/user', {
      gender,
      title,
      firstName,
      lastName,
    })

    return result.data
  }

  async setUserPassword({ currentPassword, newPassword }) {
    const result = await this.httpClient.put('api/v1/user/password', {
      currentPassword,
      newPassword,
    })

    return result.data
  }

  async deleteUserAccount({ password }) {
    const result = await this.httpClient.post('/api/v1/user/delete', {
      password,
    })

    return result.data
  }

  async getUsersSavedDevices() {
    const result = await this.httpClient.get('/api/v1/user/devices')
    return result.data
  }

  async deleteDevice(deviceId) {
    await this.httpClient.delete(`/api/v1/user/devices/${btoa(deviceId)}`)
    return
  }
}
