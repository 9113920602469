import { waitForPageReady } from './helpers'
import ACTIVITY_TYPE from './models/activityTypes'
import getEnvValue from '../utils/getEnvValue'

class AnalyticsClient {
  APP_PREFIX = getEnvValue('REACT_APP_TRACKING_APP_PREFIX')

  async trackPageView(pageName) {
    await waitForPageReady()
    this.triggerActivity(
      ACTIVITY_TYPE.PAGE_VIEW,
      `${this.APP_PREFIX}:${pageName}`
    )
  }

  async trackEvent(eventName) {
    await waitForPageReady()
    this.triggerActivity(
      ACTIVITY_TYPE.APP_EVENT,
      `${this.APP_PREFIX}:${eventName}`
    )
  }

  triggerActivity(activityType, details) {
    document.body.dispatchEvent(
      new CustomEvent(activityType, {
        detail: details,
      })
    )
  }
}

class AnalyticsClientMock {
  APP_PREFIX = getEnvValue('REACT_APP_TRACKING_APP_PREFIX')

  async trackPageView(pageName) {
    console.log('[MOCK]: Trying to track page view.')
    await waitForPageReady()
    console.log(`[MOCK]: Tracking page view: ${this.APP_PREFIX}:${pageName}`)
  }

  async trackEvent(eventName) {
    console.log('[MOCK]: Trying to track app event.')
    await waitForPageReady()
    console.log(`[MOCK]: Tracking event with name: ${eventName}`)
  }
}

let analyticsClient
const trackingEnabled = getEnvValue('REACT_APP_TRACKING_ENABLED')

if (trackingEnabled) {
  analyticsClient = new AnalyticsClient()
} else {
  analyticsClient = new AnalyticsClientMock()
}

export default analyticsClient
