import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import './button.scss'
import { useHistory } from 'react-router-dom'

const getVariantClass = (variant) => {
  switch (variant) {
    case 'primary':
      return 'brand-button--primary'
    case 'tertiary':
      return 'brand-button--tertiary'
    default:
      return 'brand-button--primary'
  }
}

const Button = ({
  children,
  className,
  variant,
  to,
  href,
  onClick,
  ...rest
}) => {
  const { push } = useHistory()

  const onClickHandler = () => {
    onClick && onClick()
    to && push(to)
    href && window.location.assign(href)
  }

  return (
    <button
      className={clsx('brand-button', getVariantClass(variant), className)}
      onClick={onClickHandler}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'tertiary']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
}

export default Button
