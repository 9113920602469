export function waitForPageReady() {
  return new Promise((resolve) => {
    //first we subscribe to be sure that nothing happen between document.readyState  change and subscription...
    window.addEventListener('load', () => {
      window.removeEventListener('load', resolve) //this is nice to have
      resolve()
    })
    if (document.readyState === 'complete') {
      resolve() // this can be called twice, not problem
    }
  })
}
