export const callStatus = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const actionType = {
  CHECK_IF_USER_IS_LOGGED_IN: 'registration/CHECK_IF_USER_IS_LOGGED_IN',
}

/**
 * @typedef {import('../services/CommonService').default} CommonService
 */

export function checkIfUserIsLoggedIn() {
  return async function (dispatch, getState, { commonService }) {
    dispatch({
      type: actionType.CHECK_IF_USER_IS_LOGGED_IN,
      status: callStatus.REQUEST,
    })

    /** @type {!CommonService} alias for better autocompletion */
    const service = commonService
    try {
      const result = await service.checkIfUserIsLoggedIn()

      dispatch({
        type: actionType.CHECK_IF_USER_IS_LOGGED_IN,
        status: callStatus.SUCCESS,
        userLoggedIn: result,
      })
    } catch (error) {
      return dispatch({
        type: actionType.CHECK_IF_USER_IS_LOGGED_IN,
        status: callStatus.ERROR,
        error: error.message,
      })
    }
  }
}
