const { REACT_APP_STATIC_ENV_GLOBAL_KEY } = process.env

let getEnvValue = (name) => {
  let globalKey = REACT_APP_STATIC_ENV_GLOBAL_KEY

  if (globalKey in window && name in window[globalKey]) {
    return window[globalKey][name]
  }

  if (name in process.env) {
    return process.env[name]
  }

  return ''
}

module.exports = getEnvValue
