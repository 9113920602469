import React from 'react'
import { Link } from 'react-router-dom'
import { withLayout } from 'app/layouts'
import DefaultLayout from 'app/layouts/default/DefaultLayout'

import './landingPage.scss'
import AnalyticsClient from 'app/tracking/analyticsClient'
import PAGE_NAMES from 'app/tracking/models/pageNames'
import BenefitsListSection from 'app/ui/BenefitsListSection'
import HeroImage from 'app/ui/HeroImage'
import Button from 'app/ui/Button'

function LandingPage() {
  AnalyticsClient.trackPageView(PAGE_NAMES.LANDING_PAGE)
  return (
    <>
      <section className="landing__hero">
        <HeroImage className="landing__hero-image" />
        <div className="landing-hero__content">
          <div className="landing-hero__content-container">
            <p className="hero__subheader">Gewerbe Portal</p>
            <h1 className="landing-hero__title">
              Ihr Zugang zum ERGO Gewerbe Portal
            </h1>
            <Button to="/portal" className="landing-hero__button">
              Anmelden
            </Button>
            <p className="landing-hero__registration-ref">
              Zugang beantragen{' '}
              <Link
                className="landing-hero__registration-ref--link"
                to="/registration"
              >
                Registrieren
              </Link>
            </p>
          </div>
        </div>
      </section>
      <BenefitsListSection />
    </>
  )
}

export default withLayout(DefaultLayout, LandingPage, {
  displayLoginInHeader: true,
})
