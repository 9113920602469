import React from 'react'

export function withLayout(
  LayoutComponent,
  WrappedComponent,
  layoutAdditionalProps = {}
) {
  return class extends React.Component {
    render() {
      return (
        <LayoutComponent {...{ ...this.props, ...layoutAdditionalProps }}>
          <WrappedComponent {...this.props} />
        </LayoutComponent>
      )
    }
  }
}
