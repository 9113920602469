import axios from 'axios'

export default class RegistrationService {
  /** @type axios */
  httpClient

  constructor() {
    this.httpClient = axios.create()
    this.httpClient.interceptors.response.use(
      (response) => response,
      (err) => {
        const statusCode = err.response.status

        if (statusCode === 422) {
          const message = 'Ihre Sitzung ist abgelaufen.'
          alert(message)
        }

        if (statusCode >= 500) {
          const message =
            'Es ist ein Kommunikationsfehler aufgetreten (' +
            statusCode +
            ').\nBitte versuchen Sie es später erneut.'
          alert(message)
        }

        return Promise.reject(err.response)
      }
    )
  }

  /**
   * @param {!String} vnr
   * @param {!String} postCode
   */
  async checkVnr(vnr, postCode) {
    const result = await this.httpClient.post('/api/v1/checkVnr', {
      vnr,
      postCode,
    })
    return result.data
  }

  /**
   * @param {!String} token
   */
  async checkRegistrationLink(token) {
    if (!token) {
      return
    }

    try {
      const result = await this.httpClient.post(
        '/api/v1/registrationFromLink',
        { token }
      )
      return result.data
    } catch (e) {
      return
    }
  }

  async sendUserRegistrationData({
    gender,
    title,
    firstName,
    lastName,
    email,
    password,
    vnr,
    knr,
  }) {
    const result = await this.httpClient.post('/api/v1/registration', {
      gender,
      title,
      firstName,
      lastName,
      email,
      password,
      vnr,
      knr,
    })
    return result.data
  }

  async verifyEmail(token) {
    const result = await this.httpClient.post('/api/v1/verifyEmail', { token })
    return result.data
  }
}
