import { actionType, callStatus } from './commonActions'

const initialState = {
  checkIfUserIsLoggedInCall: { status: null },
}

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.CHECK_IF_USER_IS_LOGGED_IN:
      return {
        ...state,
        userLoggedIn:
          action.status === callStatus.SUCCESS
            ? action.userLoggedIn
            : undefined,
        checkIfUserIsLoggedInCall: {
          status: action.status,
          error: action.error,
        },
      }
    default:
      return state
  }
}
