import checkIcon from './check.svg'
import React from 'react'
import './benefitsListSection.scss'

const BENEFITS = [
  {
    title: 'Ihre Verträge online',
    description: 'Verwalten Sie Ihre Verträge einfach und bequem',
  },
  {
    title: 'Digitales Postfach',
    description:
      'Erhalten Sie Ihre Post online: direkt, übersichtlich und umweltschonend',
  },
  {
    title: 'Schaden online melden',
    description:
      'Regulieren Sie Rechnungen und Schäden schnell und unkompliziert',
  },
]

const BenefitsListSection = () => (
  <section className="benefits__section">
    <div className="benefits__container">
      <div className="benefits__header">
        <p className="benefits__header-content">Ihre Vorteile</p>
      </div>
      <ul className="benefits__list">
        {BENEFITS.map(({ title, description }) => (
          <li key={title} className="benefits__list-item">
            <img
              src={checkIcon}
              className="benefits__list-item-icon"
              alt="Check icon"
            />
            <p className="benefits__list-item-content">
              <strong>{title}</strong> – {description}
            </p>
          </li>
        ))}
      </ul>
    </div>
  </section>
)

export default BenefitsListSection
