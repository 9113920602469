import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LandingPage from './pages/LandingPage'

require('./app.scss')
require('@eg/elements/styles/styles.css')

const RegistrationModule = lazy(() =>
  import(
    /* webpackChunkName: "registration-module" */ './modules/registration/RegistrationModule'
  )
)
const PortalModule = lazy(() =>
  import(
    /* webpackChunkName: "portal-module" */ './modules/portal/PortalModule'
  )
)
const PasswordResetlModule = lazy(() =>
  import(
    /* webpackChunkName: "password-reset-module" */ './modules/password-reset/PasswordResetlModule'
  )
)

export default class App extends React.Component {
  static createAndRender(store) {
    let viewComponent = <App store={store} />
    ReactDOM.render(viewComponent, document.getElementById('root'))
  }

  render() {
    return (
      <ReduxProvider store={this.props.store}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/portal" component={PortalModule} />
              <Route path="/registration" component={RegistrationModule} />
              <Route path="/password-reset" component={PasswordResetlModule} />
            </Switch>
          </Suspense>
        </Router>
      </ReduxProvider>
    )
  }
}
