import { combineReducers } from 'redux'
import { createStore, applyMiddleware } from 'redux'

/**
 * Helps create redux store but also ads special handlers to redux store
 * to enable possibility off adding dynamic reducers by lazy loaded modules.
 */
export default class ReduxStoreManager {
  _preloadedState
  _middlewares = []
  _staticReducers = {}
  _dynamicReducers = {}
  _middlewareComposeFunc = (it) => it

  static create() {
    return new ReduxStoreManager()
  }

  withMiddleware(middleware) {
    this._middlewares.push(middleware)
    return this
  }

  withStaticReducer(key, reducerFunc) {
    this._staticReducers[key] = reducerFunc
    return this
  }

  withExtraMiddlewareCompose(composeFunc) {
    this._middlewareComposeFunc = composeFunc
    return this
  }

  build() {
    const store = createStore(
      this._combineReducers(),
      this.preloadedState,
      this.__applyMiddlewares()
    )
    this._attachHandlers(store)
    return store
  }

  /**
   *
   * @param {Object} store
   * @private
   */
  _attachHandlers(store) {
    store.injectReducer = (reducerKey, reducerFunc) => {
      this._dynamicReducers[reducerKey] = reducerFunc
      store.replaceReducer(this._combineReducers())
    }

    store.injectReducers = (reducersMap = {}) => {
      this._dynamicReducers = { ...this._dynamicReducers, ...reducersMap }
      store.replaceReducer(this._combineReducers())
    }

    return store
  }

  /**
   * @private
   */
  _combineReducers() {
    return combineReducers({
      ...this._staticReducers,
      ...this._dynamicReducers,
    })
  }

  /**
   * @private
   */
  __applyMiddlewares() {
    return this._middlewareComposeFunc(applyMiddleware(...this._middlewares))
  }
}
