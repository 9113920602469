import React from 'react'

import ergoLogo from '../images/ergo-logo.svg'
import personIcon from '../images/icon-person.svg'
import logoutIcon from '../images/logout-icon.svg'
import './header.scss'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'

const NotLoggedInLinks = () => (
  <>
    <Link
      to="/registration"
      className={clsx('user-links__link', 'user-links__link--margin')}
    >
      <p className="user-links__label">Registrieren</p>
    </Link>
    <span className="user-links__link--margin user-links__link--divider">
      |
    </span>
    <Link to="/portal" className="user-links__link">
      <img src={personIcon} alt="Profile icon" />
      <p className="user-links__label">Anmelden</p>
    </Link>
  </>
)

const LogoutLink = ({ oauthConfig }) => (
  <a
    className="user-links__link"
    href={`${
      oauthConfig.serverUrl
    }/perform_logout?redirect_uri=${encodeURIComponent(
      oauthConfig.logoutRedirectUrl
    )}`}
  >
    <img src={logoutIcon} alt="Logout icon" />
    <p className="user-links__label">Abmelden</p>
  </a>
)

const Header = ({ user, oauthConfig, displayLoginInHeader }) => {
  const history = useHistory()
  const isCurrentLogoutPage = history.location.pathname === '/portal/logout'
  const shouldDisplayLinks = displayLoginInHeader && !isCurrentLogoutPage

  return (
    <nav className="header">
      <div className="logo__container">
        <img className="logo" src={ergoLogo} alt="ERGO logo" />
        <p className="logo__label">Gewerbe Portal</p>
      </div>
      {shouldDisplayLinks && (
        <div className="links__container">
          {user ? <LogoutLink {...{ oauthConfig }} /> : <NotLoggedInLinks />}
        </div>
      )}
    </nav>
  )
}

export default Header
