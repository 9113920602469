import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import App from './App'

import commonReducer from './store/commonReducer'
import getEnvValue from './utils/getEnvValue'

// `app` is an resolve alias pointing at `src` folder
// Don't use it when we want to import some module specific / private dependencis which
// are placed in sub folder like './rows/RowWithVNRNumber'
// Use it when you want to use common / public dependencies like 'app/ui/Card'
import CommonService from 'app/services/CommonService'
import RegistrationService from 'app/services/RegistrationService'
import PasswordResetService from 'app/services/PasswordResetService'
import PortalService from 'app/services/PortalService'
import ReduxStoreManager from 'app/utils/ReduxStoreManager'
import initTracking from './tracking/init'

// import * as serviceWorker from './serviceWorker'
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

const immutableMiddleware = require('redux-immutable-state-invariant').default()

const commonService = new CommonService()
const registrationService = new RegistrationService()
const passwordResetService = PasswordResetService.create()
const portalService = new PortalService()

const services = {
  commonService,
  registrationService,
  passwordResetService,
  portalService,
}

const store = ReduxStoreManager.create()
  .withStaticReducer('common', commonReducer)
  .withStaticReducer('config', configReducer)
  .withMiddleware(thunk.withExtraArgument(services))
  .withMiddleware(immutableMiddleware)
  .withExtraMiddlewareCompose(composeWithDevTools)
  .build()

initTracking()
App.createAndRender(store, services)

export function configReducer(state, action) {
  return {
    oauth: {
      serverUrl: getEnvValue('REACT_APP_AUTH_SERVER_URL'),
      logoutRedirectUrl: getEnvValue('REACT_APP_LOGOUT_REDIRECT_URL'),
      loginRedirectUrl: getEnvValue('REACT_APP_LOGIN_REDIRECT_URL'),
      accountDeletedRedirectUrl: getEnvValue(
        'REACT_APP_ACCOUNT_DELETED_REDIRECT_URL'
      ),
    },
    registrationReturnUrl: getEnvValue('REACT_APP_FIXED_RETURN_TO_SURETY_URL'),
    resetPasswordReturnUrl: getEnvValue('REACT_APP_FIXED_RETURN_TO_SURETY_URL'),
    registrationRedirectUrlForB2C: getEnvValue(
      'REACT_APP_REGISTRATION_REDIRECT_URL_FOR_B2C'
    ),
  }
}
if (
  getEnvValue('NODE_ENV') === 'development' ||
  getEnvValue('NODE_ENV') === 'integration'
) {
  console.log('Use following env variables: ', process.env)
}
