import axios from 'axios'

export default class PasswordResetService {
  static create(httpClient) {
    /**
     * @type {axios}
     */
    let client = axios.create({
      baseURL: '/api/v1/password-reset',
      timeout: 10000,
    })
    client.interceptors.response.use(
      (id) => id,
      (err) => {
        alert(
          'Es ist ein Kommunikationsfehler aufgetreten.\nBitte versuchen Sie es später erneut.'
        )
        return Promise.reject(err.response)
      }
    )
    return new PasswordResetService(client)
  }

  /**
   * @type {axios}
   */
  httpClient

  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async triggerPasswordReset(email) {
    const result = await this.httpClient.post('/triggerReset', {
      email: email,
    })
    return result.data
  }

  async checkToken(token) {
    const result = await this.httpClient.get('/checkToken', {
      params: {
        token: token,
      },
    })
    return result.data
  }

  async setPassword(password, token) {
    const result = await this.httpClient.post('/setPassword', {
      password: password,
      token: token,
    })
    return result.data
  }
}
