import axios from 'axios'

export default class CommonService {
  /** @type axios */
  httpClient

  constructor() {
    this.httpClient = axios.create()
  }

  async checkIfUserIsLoggedIn() {
    const result = await this.httpClient.get('/api/v1/isUserLoggedIn')
    return result.data
  }
}
