import React from 'react'
import heroBg375webp from './img/375.webp'
import heroBg375 from './img/375.jpg'
import heroBg750webp from './img/750.webp'
import heroBg750 from './img/750.jpg'
import heroBg1600webp from './img/1600.webp'
import heroBg1600 from './img/1600.jpg'
import heroBg2400webp from './img/2400.webp'
import heroBg2400 from './img/2400.jpg'
import './heroImage.scss'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const HeroImage = ({ className }) => (
  <picture className={clsx('hero__image-container', className)}>
    <source
      media="(max-width: 400px)"
      srcSet={heroBg375webp}
      type="image/webp"
    />
    <source media="(max-width: 400px)" srcSet={heroBg375} type="image/jpeg" />
    <source
      media="(max-width: 1023px)"
      srcSet={heroBg750webp}
      type="image/webp"
    />
    <source media="(max-width: 1023px)" srcSet={heroBg750} type="image/jpeg" />
    <source
      media="(max-width: 1599px)"
      srcSet={heroBg1600webp}
      type="image/webp"
    />
    <source media="(max-width: 1599px)" srcSet={heroBg1600} type="image/jpeg" />
    <source
      media="(min-width: 1600px)"
      srcSet={heroBg2400webp}
      type="image/webp"
    />
    <source media="(min-width: 1600px)" srcSet={heroBg2400} type="image/jpeg" />
    <img
      className="hero__image--background"
      src={heroBg375}
      alt="Smiling women waiting for clients"
    />
  </picture>
)

HeroImage.propTypes = {
  className: PropTypes.string,
}

export default HeroImage
