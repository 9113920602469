import React from 'react'
import ErgoStyleProvider from '@eg/elements/Provider'
import Header from './Header'
import Footer from './Footer'

require('./layout.scss')

const DefaultLayout = ({
  children,
  user,
  oauthConfig,
  displayLoginInHeader,
}) => (
  <ErgoStyleProvider theme="ergo">
    <div className="layout-container">
      <Header
        user={user}
        oauthConfig={oauthConfig}
        displayLoginInHeader={displayLoginInHeader}
      />
      <main>{children}</main>
      <Footer />
    </div>
  </ErgoStyleProvider>
)

export default DefaultLayout
