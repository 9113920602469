import React from 'react'
import ergoLogo from '../images/ergo-logo.svg'
import dkvLogo from '../images/dkv-logo.svg'
import './footer.scss'
import clsx from 'clsx'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="brand-footer">
      <div className="logos-wrapper">
        <img className="ergo-logo" src={ergoLogo} alt="ERGO" />
        <img className="dkv-logo" src={dkvLogo} alt="DKV" />
      </div>
      <div className="copyright-wrapper">
        <p className="footer__text">{`© ${currentYear} ERGO Versicherung AG`}</p>
        <span className="footer__text-divider">|</span>
        <p className="footer__text">Alle Rechte vorbehalten.</p>
        <span className="footer__text-divider">|</span>
        <a
          className={clsx('footer__text', 'footer__link')}
          href="http://www.ergo.de/de/Service/Datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Datenschutz
        </a>
      </div>
    </footer>
  )
}

export default Footer
