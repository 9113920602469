import getEnvValue from '../utils/getEnvValue'

export default function () {
  polyfillCustomEvent()
  const scriptUrl = getEnvValue('REACT_APP_TRACKING_SCRIPT_URL')

  const adobeLaunchScript = document.createElement('script')
  adobeLaunchScript.setAttribute('src', scriptUrl)
  adobeLaunchScript.async = true
  document.head.appendChild(adobeLaunchScript)
}

function polyfillCustomEvent() {
  if (typeof window.CustomEvent === 'function') return false

  console.warn('Polyfilling CustomEvent!')

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null }
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  window.CustomEvent = CustomEvent
  return true
}
